import styled from '@emotion/styled';
import { useState, useEffect } from 'react';

import { regularLightTextStyle, regularTextStyle } from '../styles/_typographies';
import CommandBox from './CommandBox';
import Greet from './Greet';
import Clock from './Clock';
import actions from '../helpers/commands/actions';
import { genUuid } from '../helpers/utils';

const DirDiv = styled.div`
    color: ${(props) => props.theme.colors.papayaWhip};
`;

const ShellPromptDiv = styled.div`
    color: ${(props) => props.theme.colors.white};
`;

const NotFoundDiv = styled.div`
    padding-left: 10px;
    color: ${(props) => props.theme.colors.white};
    ${regularLightTextStyle};
`;

const PromptDiv = styled.div`
    display: flex;
    height: 32px;  
    align-items: center;
    justify-content: left;
    padding-left: 10px;
    ${regularTextStyle};
    > * + * {
        margin-left: 10px
    } 
`;

const PromptsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
`;

function Prompt({dir, setClear, setRenderNext}){
    const [cmd, setCmd] = useState({});
    const [output, setOutput] = useState();
    
    useEffect(() => {
        if (cmd.command){
            // clear command 
            if (cmd.command === 'clear'){
                setClear(true);
                setRenderNext(true);
                return;
            }            
            if (actions[cmd.command]){
                const action = actions[cmd.command];
                setOutput(action(cmd.args));
            } 
            else {
                const o = () => (
                    <NotFoundDiv>
                        Command not found : '{cmd.command}'.
                    </NotFoundDiv>
                )
                setOutput(o);
            }
            setRenderNext(true);
        }
    }, [cmd]);

    return (
        <>
        <PromptDiv>            
            <DirDiv>{dir}</DirDiv>
            <ShellPromptDiv>%</ShellPromptDiv>
            <CommandBox setCmd={setCmd}/>
        </PromptDiv>
        <div>
            {output}
        </div>
        </>
    );
}

function Prompts(){
    const [renderNext, setRenderNext] = useState(false);
    const [clear, setClear] = useState(false);
    const [greet, setGreet] = useState(true);
    const [user, setUser] = useState('guest');
    const [dir, setDir] = useState('~');
    
    const p = {
        key: genUuid(),
        user: user,
        dir: dir,
    }
    const [prompts, setPrompts] = useState([p]);

    useEffect(()=> {
        if (renderNext){
            setRenderNext(false)
            if (clear){
                setClear(false);
                setGreet(false);
                setPrompts([{
                    key: genUuid(),
                    user: user,
                    dir: dir,
                }]);
                return;
            }
            setPrompts(prompts.concat(p));
        }
    }, [renderNext])

    if (greet) {
        return (
            <PromptsContainer>
                <Clock/>
                {prompts.map((prompt)=> {
                    return <Prompt 
                        key={prompt.key} 
                        user={prompt.user} 
                        dir={prompt.dir}
                        setUser={setUser}
                        setDir={setDir}
                        setClear = {setClear}
                        setRenderNext={setRenderNext}
                    />
                })}
            </PromptsContainer>
        )
    } else {
        return (
            <PromptsContainer>            
                {prompts.map((prompt)=> {
                    return <Prompt 
                        key={prompt.key} 
                        user={prompt.user} 
                        dir={prompt.dir}
                        setUser={setUser}
                        setDir={setDir}
                        setClear = {setClear}
                        setRenderNext={setRenderNext}
                    />
                })}
            </PromptsContainer>
        )
    }    
}

export default Prompts;
