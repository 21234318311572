import React from 'react';
import TxtOutput from '../../components/output/TxtOutput';
import LsOutput from '../../components/output/LsOutput';
import { files } from '../../content/home.js'
import RegularOutput from '../../components/output/RegularOutput';
import { fileType } from '../utils';
import Cowsay from 'react-cowsay';
import { browserName } from 'react-device-detect';


const needsArg = (cmd) => {
   return <TxtOutput lines={[`'${cmd}' needs an argument.`]}/>;
}

const noSuchFile = (file) => {
    return <TxtOutput lines={[`ls: ${file} No such file or directory`]}/>;
}

const noSuchDirectory = (dir) => {
    return <TxtOutput lines={[`cd: The directory '${dir}' does not exist`]}/>;
}

const ls = (args) => {
    const l = args.length;
    let longOption = false;
    if (l > 0){
        let dir = ".";
        if (args[0] === "-l"){
            longOption = true;
            if (l > 2){
                return <TxtOutput lines={[`one argument only please.`]}/> 
            }
            if (l === 2){
                dir = args[1];
            }
        } else {
            if (l > 1){
                return <TxtOutput lines={[`one argument only please.`]}/> 
            }
            dir = args[0];
        }
        if (dir !== "."){
            if (files[dir]){
                return <LsOutput longOption={longOption} files={[files[dir]]}/>;
            }
            return <TxtOutput lines={[`No such file or directory: '${dir}'`]}/>
        } 
    }
    return <LsOutput longOption={longOption} files={Object.values(files)}/>;
}

const cat = (args) => {
    if (!args || args.length === 0) {
        return needsArg("cat")
    } 
    const file = args[0];
    if (files[file]){
        if(files[file].name === 'me.jpg'){
            return <RegularOutput output={
                <Cowsay 
                    text="Cheese!" e="^^" T=" U "
                />
                }
            />;
        }
        else{
            return <TxtOutput lines={files[file].content}/>;
        }        
    }
    return noSuchFile(file);
}

const ping = () => {
    return (
        <RegularOutput output={
                <Cowsay text="PONG!!!"/>
            }
        />
    )
}

const uname = () => {
    return (
        <RegularOutput output="Darwin (Núñez)" />
    )
}

const whoami = () => {
    return (
        <RegularOutput output={browserName.toLowerCase().replace(/\s/g, '')} />
    )
}

const pwd = () => {
    return (
        <RegularOutput output="/home/goz" />
    )
}

const cd = (args) => {
    if (!args || args.length === 0) {
        return '';
    }
    const dir = args[0];    
    if (dir === '.') {
        return '';
    }
    return noSuchDirectory(dir);
}

const echo = (args) => {
    if (!args || args.length === 0) {
        return '';
    }
    const params = args.join(' ');
    if (params === '$shell') {
        return <RegularOutput output="fish" />
    }
    if (params === '$path') {
        return <RegularOutput output="/usr/local/bin" />
    }
    console.log(params);
    return <RegularOutput output={params.replace(/['"]+/g, '')} />;
}

const sh = (args) => {
    if (!args || args.length === 0) {
        return needsArg("sh")
    }
    const file = args[0];
    if (!files[file]){
        return noSuchFile(file);
    }
    if (files[file].type !== fileType.exec){
        return <TxtOutput lines={[`'${file}' is not an executable file.`]}/>
    }
    return files[file].run(args);
}

const actions = {
    'ls': ls,
    'cat': cat,
    'ping': ping,
    'uname': uname,
    'whoami': whoami,
    'pwd': pwd,
    'cd': cd,
    'sh': sh,
    'echo': echo
}

export default actions;
